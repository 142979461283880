import { MBuild, MDocument } from ".";

export class MApplication {
  id: number = 0;
  key: string = "";
  name: string = "";
  type: string = "";
  isActive: boolean = false;
  isTop: boolean = false;
  Builds: MBuild[] = [];
  url?: string;
  Icon?: MDocument;
  Card?: MDocument;
  Video?: MDocument;
  Build?: MBuild;
  description?: string = "";
}
