import { Menu } from "antd";

import './tabs.scss';

interface YcTabsElems {
  key: string,
  label: string,
  icon?: React.ReactNode
};

export function YcTabs(props: {
  tab: string,
  elems: YcTabsElems[],
  onClick: (e: { key: string }) => void,
}) {

  return (
    <div className="yc-tabs">
      <Menu onClick={(e) => { props.onClick(e) }} selectedKeys={[props.tab]} mode="horizontal" >
        {props.elems.map((elem) =>
          <Menu.Item key={elem.key} icon={elem.icon}>
            {elem.label}
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
}