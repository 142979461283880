import { BaseService } from "./base.service";
import { UserAuth } from "../contexts/auths.context";

class AuthsService extends BaseService {

  PATH = "auths/";

  async signup(data: any): Promise<UserAuth | null> {
    const login = await this.post(this.PATH + "signup", data);
    if (login?.token) {
      this.setToken(login.token);
      return login.user;
    }
    return null;
  }

  async login(data: any): Promise<UserAuth | null> {
    const login = await this.post(this.PATH + "login", data);
    if (login?.token) {
      this.setToken(login.token);
      return login.user;
    }
    return null;
  }

  async autologin(): Promise<UserAuth | null> {
    if (!!this.getToken()) {
      const login = await this.get(this.PATH + "autologin", {});
      if (login?.token) {
        this.setToken(login.token);
        return login.user;
      }
    }
    return null;
  }

  async logout(): Promise<boolean> {
    await this.get(this.PATH + "logout", {});
    this.delToken();
    return true;
  }

}

export const authsService = new AuthsService();