import { Form, Input, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { usersService } from '../../../../services/users.service';
import { MUser } from '../../../../modeles';

import { YcTitle } from '../../../../components';

import './user.page.scss';

export function UserPage() {

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [user, _setUser] = useState<MUser>(new MUser());

  const uHistory = useHistory();
  const uParams: any = useParams();

  useEffect(() => {
    if (!!uParams['id']) {
      usersService.view(uParams['id']).then((data) => {
        if (data) {
          _setUser(data);
          _setIsReady(true);
        } else {
          uHistory.push("/users");
        }
      });
    } else {
      _setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onFinish = async (values: any) => {
    const c = { ...user, ...values };
    if (!!user?.id) {
      const data = await usersService.edit(user?.id, c);
      if (data) {
        _setUser(data);
      }
    } else {
      const data = await usersService.add(c)
      if (data) {
        _setUser(data);
        uHistory.push("/users/user/" + data?.id);
      }
    }
  };

  return (
    <div id='root-user'>
      {isReady &&
        <>
          <YcTitle links={[{ link: "/users", label: "Users" }]} label={user.email}></YcTitle>
          {!user.id &&
            <Form onFinish={onFinish} layout="vertical">
              <div className='row'>
              <div className='col-4'>
                  <Form.Item label="Email" name="email" rules={[{ required: !user.id }]} initialValue={user.email}>
                    <Input type="text" />
                  </Form.Item>
                </div>
                <div className='col-4'>
                  <Form.Item label="Password" name="password" rules={[{ required: !user.id }]}>
                    <Input type="text" />
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <Button type="primary" htmlType='submit' className='mt-4'>
                  {!user.id && <>Create User</>}
                  {!!user.id && <>Edit User</>}
                </Button>
              </Form.Item>
            </Form>
          }
        </>
      }
    </div >
  );
}