import { Form, Input, Button, Upload, Table, Select, message, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';

import { applicationsService } from '../../../../services';
import { MApplication, MBuild, MDocument } from '../../../../modeles';
import { YcTabs, YcTitle, YcUploadFile } from '../../../../components';
import slugify from 'slugify';

import './application.page.scss';
import { ApplicationTypes } from '../../../../enums';

export function ApplicationPage() {
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [loading, _setLoading] = useState<boolean>(false);
  const [tab, _setTab] = useState('edit');
  const [application, _setApplication] = useState<MApplication>(new MApplication());
  const [version, _setVersion] = useState('');

  const uHistory = useHistory();
  const uParams: any = useParams();

  const propsUpload = () => {
    return {
      name: 'build',
      action: process.env.REACT_APP_API_URL + '/applications/upload-version/' + application.id + '?version=' + version,
      headers: {
        authorization: localStorage.getItem('user-token') || '',
      },
      onChange(info: any) {
        if (info.file.status === 'done') {
          application.Builds = [info.file.response.data, ...application.Builds];
          _setApplication({ ...application });
          _setVersion('');
        } else if (info.file.status === 'error') {
          const msg = applicationsService._getMessage(info.file.response);
          if (msg) {
            message.error(msg);
          }
        }
      },
    };
  };

  useEffect(() => {
    if (!!uParams['id']) {
      applicationsService.view(uParams['id']).then((data) => {
        if (data) {
          _setApplication(data);
          _setIsReady(true);
        } else {
          uHistory.push('/applications');
        }
      });
    } else {
      _setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    const c = { ...application, ...values };
    if (!!application?.id) {
      const data = await applicationsService.edit(application!.id, c);
      if (data) {
        _setApplication(data);
      }
    } else {
      const data = await applicationsService.add(c);
      if (data) {
        _setApplication(data);
        uHistory.push('/applications/application/' + data?.id);
      }
    }
  };

  const onBuild = async (build: MBuild) => {
    _setLoading(true);
    const builds = await applicationsService.buildCurrect(application.id, build.id);
    _setLoading(false);
    if (builds) {
      _setApplication({
        ...application,
        Builds: builds,
      });
    }
  };

  const columns: ColumnType<MBuild>[] = [
    {
      title: 'Version',
      key: 'version',
      dataIndex: 'version',
    },
    {
      title: 'Link',
      key: 'link',
      render: (value: string, build: MBuild) => (
        <>
          <a href={build.url} target="_blank" rel="noreferrer">
            {build.url}
          </a>
        </>
      ),
    },
    {
      title: 'Current',
      key: 'isCurrent',
      render: (value: string, build: MBuild) => (
        <>
          {build.isCurrent && <Button type="primary" shape="circle" icon={<CheckOutlined />} onClick={() => onBuild(build)} />}
          {!build.isCurrent && <Button type="ghost" shape="circle" onClick={() => onBuild(build)} icon={<CheckOutlined />} />}
        </>
      ),
    },
  ];

  const slug = (str: string): string => {
    str = str.replace(/[^A-Za-z0-9À-ÖØ-öø-ÿ-_& ]/g, '');
    return slugify(str, {
      lower: true,
    });
  };

  return (
    <div id="applications-application">
      {isReady && (
        <Spin spinning={loading}>
          <YcTitle links={[{ link: '/applications', label: 'Applications' }]} label={application.name}></YcTitle>
          <YcTabs
            tab={tab}
            elems={[
              {
                key: 'edit',
                label: 'Edit',
              },
              {
                key: 'versions',
                label: 'Versions (' + application.Builds.length + ')',
              },
            ]}
            onClick={(e) => {
              _setTab(e.key);
            }}
          />
          <div className="mt-3">
            {tab === 'edit' && (
              <Form onFinish={onFinish} layout="vertical">
                <div className="files">
                  <YcUploadFile
                    type="image"
                    label="Icon"
                    name="icon"
                    width={1024}
                    height={1024}
                    displayHeight={100}
                    path={'/applications/upload-icon'}
                    defaultValue={application.Icon}
                    onUpload={(doc: MDocument) => {
                      _setApplication({ ...application, Icon: doc });
                    }}
                  />
                  <YcUploadFile
                    type="image"
                    label="Card"
                    name="card"
                    width={960}
                    height={600}
                    displayHeight={100}
                    path={'/applications/upload-card'}
                    defaultValue={application.Card}
                    onUpload={(doc: MDocument) => {
                      _setApplication({ ...application, Card: doc });
                    }}
                  />
                  <YcUploadFile
                    type="video"
                    label="Video"
                    name="video"
                    width={1792}
                    height={1024}
                    displayHeight={100}
                    path={'/applications/upload-video'}
                    defaultValue={application.Video}
                    onUpload={(doc: MDocument) => {
                      _setApplication({ ...application, Video: doc });
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={application.name}>
                      <Input
                        type="text"
                        onChange={(e) => {
                          if (!application.id) {
                            application.key = slug(e.target.value);
                            _setApplication({ ...application });
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-4">
                    <Form.Item label="Key (will be on the url)">
                      <Input type="text" value={application.key} disabled={true} />
                    </Form.Item>
                  </div>
                  <div className="col-4">
                    <Form.Item label="Type" name="type" initialValue={application.type}>
                      <Select>
                        {ApplicationTypes.map((e) => (
                          <Select.Option key={e.key as string} value={e.key}>
                            {e.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <Form.Item label="Description" name="description" initialValue={application.description}>
                      <Input type="text" value={application.description} />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="mt-4">
                    {!application.id && <>Create Application</>}
                    {!!application.id && <>Edit Application</>}
                  </Button>
                </Form.Item>
              </Form>
            )}

            {tab === 'versions' && !!application.id && (
              <div>
                <Form layout="inline" className="mb-3">
                  <Form.Item>
                    <Input
                      type="text"
                      placeholder="version: 1.0.0"
                      value={version}
                      onChange={(e) => {
                        _setVersion(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Upload {...propsUpload()}>
                    <Button type="primary" icon={<UploadOutlined />}>
                      Upload new build
                    </Button>
                  </Upload>
                </Form>
                <Table columns={columns} dataSource={application.Builds} rowKey="id"></Table>
              </div>
            )}
          </div>
        </Spin>
      )}
    </div>
  );
}
