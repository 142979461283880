import { useEffect, useState } from 'react';
import { YcGamePreview, YcFirstTopGame } from '../../../../components';
import { gamesService, GamesServiceHome } from '../../../../services';
import './home.page.scss';

export function HomePage() {
  const [games, _setGames] = useState<GamesServiceHome | null>(null);

  useEffect(() => {
    gamesService.home().then((data) => {
      if (data) {
        _setGames(data);
      }
    });
  }, []);

  return (
    <div id="root-home">
      {!!games && (
        <div className="categories">
          <div className="category">
            <div className="header-category-style">
              <i className="ri-trophy-line ico-style"></i>
              <h2>Top games</h2>
            </div>
            <div className="games scrollright">
              <div>
                <div className="game">
                  <YcFirstTopGame application={games.tops[0]} />
                </div>
              </div>
              <div>
                {games.tops.map(
                  (game) =>
                    game.id !== games.tops[0].id && (
                      <div className="game">
                        <YcGamePreview application={game} height={250} />
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
          {games.categories.map((category) => (
            <>
              {category.games.length > 0 && (
                <div className="category category-style">
                  <div className="header-category-style">
                    <i className="ri-gamepad-line ico-style"></i>
                    <h2>{category.label}</h2>

                    <div className="content-bt-collapse">
                      <button className="bt-collapse">
                        <div className="ri-arrow-left-s-line" />
                      </button>
                      <button className="bt-collapse">
                        <div className="ri-arrow-right-s-line" />
                      </button>
                    </div>
                  </div>

                  <div className="games">
                    {category.games.map((game) => (
                      <div className="game">
                        <YcGamePreview application={game} height={130} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
}
