import React, { useEffect } from 'react';

import { authsService } from "../services/auths.service";
import { useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';

class UserAuthAccount {
  id: number = 0;
  name: string = "";
}

export class UserAuth {
  id: number = 0;
  accountId: number = 0;
  email: string = "";
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  Account: UserAuthAccount = new UserAuthAccount();
}

export interface AuthsContextType {
  user: UserAuth | null;
  _setUser: React.Dispatch<React.SetStateAction<UserAuth | null>>;
  isLogin: () => boolean;
  isAdmin: () => boolean;
  isReady: () => boolean;
  isSuperAdmin: () => boolean;
  signup: (data: any) => Promise<void>;
  login: (data: any) => Promise<void>;
  autologin: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthsContext = React.createContext<AuthsContextType | null>(null);

export function AuthsContextProvider({ children }: { children: React.ReactNode }) {

  const [user, _setUser] = useState<UserAuth | null>(null);
  const [ready, _setReady] = useState(false);

  const uHistory = useHistory();
  const uLocation = useLocation();

  const value: AuthsContextType = {
    user: user,
    _setUser,
    isLogin() {
      return !!user;
    },
    isReady() {
      return ready;
    },
    isAdmin() {
      return this.isLogin() && user!.isAdmin;
    },
    isSuperAdmin() {
      return this.isLogin() && user!.isSuperAdmin;
    },
    async signup(data: any) {
      const user = await authsService.signup(data);
      if (user != null) {
        _setUser(user);
      }
    },
    async login(data: any) {
      const user = await authsService.login(data);
      if (user != null) {
        _setUser(user);
        uHistory.push("/");
      }
    },
    async autologin() {
      const user = await authsService.autologin();
      if (user) {
        _setUser(user);
        if (uLocation.pathname === "/login") {
          uHistory.push("/");
        }
      }
      _setReady(true);
    },
    async logout() {
      await authsService.logout();
      _setUser(null);
    }
  };

  useEffect(() => {
    value.autologin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthsContext.Provider value={value}>
      {children}
    </AuthsContext.Provider>
  );
}

export function useAuths() {
  const context = React.useContext(AuthsContext);
  if (!context) throw new Error('No AuthsContext provider found!');
  return context;
}