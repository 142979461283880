import { Form, Input, Button, Card, Popconfirm, Switch } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { profileService } from '../../../../services/profile.service';

import './profile.page.scss';
import { useEffect, useState } from 'react';
import { MAccount } from '../../../../modeles';
import TextArea from 'antd/lib/input/TextArea';

function Field(props: {
  value: string;
  label: string;
  onChange: (value: string) => void
}) {

  async function onGenerate() {
    const k = await profileService.generateKey();
    if (k) {
      props.onChange(k);
    }
  }

  return (
    <Form.Item label={props.label} rules={[{ required: true }]}>
      <Input.Group compact>
        <Input style={{ width: 'calc(100% - 32px)' }} value={props.value} disabled={true} />
        <Popconfirm title={() =>
          <>
            Do you realy want to Regenerate it ?<br />
            If you already use this key, you have to replace it every where.
          </>
        } placement="topRight" okText="Regenerate" onConfirm={onGenerate}>
          <Button type="primary" icon={<ReloadOutlined />} />
        </Popconfirm>
      </Input.Group>
    </Form.Item>
  );

}

export function ProfilePage() {

  const [account, _setAccount] = useState<MAccount | null>(null);

  const onUpdatePassword = async (data: any) => {
    await profileService.updatePassword(data);
  }

  const onUpdateTenjin = async (data: any) => {
    await profileService.updateTenjin(data);
  }

  const onUpdateKeys = async (data: any) => {
    if (account) {
      const usersApis = await profileService.updateKeys(account!.UsersApis!);
      if (usersApis) {
        _setAccount({
          ...account,
          UsersApis: usersApis,
        });
      }
    }
  }

  useEffect(() => {
    profileService.view().then((a) => {
      _setAccount(a);
    });
  }, []);

  return (
    <div className='root-profile'>
      <h1>Profile</h1>
      {!!account &&
        <div className="row">
          <div className='col-lg-4'>
            <Card title="Credit Line this mounth" >
              <table className='table'>
                <tbody>
                  <tr>
                    <th>Credit Line<span style={{ 'float': 'right' }}>+</span></th>
                    <td>{"$" + account?.creditLine?.toLocaleString("en")}</td>
                  </tr>
                  <tr>
                    <th>Spend <span style={{ 'float': 'right' }}>-</span></th>
                    <td>{"$" + account?.creditLineSpend?.toLocaleString("en")}</td>
                  </tr>
                  <tr>
                    <th>Remender <span style={{ 'float': 'right' }}>=</span></th>
                    <td>{"$" + account?.creditLineRemender?.toLocaleString("en")}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className='col-lg-8'>
            {account !== null &&
              <Card title="Keys" className="keys">
                <Form onFinish={onUpdateKeys} layout="vertical">
                  {account.UsersApis?.map((api, index) => (
                    <div className={"key" + (api.isActive ? "" : " inactive")} key={index}>
                      {!api.id &&
                        <span className="new">
                          <a className="remove" onClick={() => {
                            _setAccount({
                              ...account,
                              UsersApis: account.UsersApis!.filter((a) => a !== api),
                            });
                          }} href="#/">x</a>
                          New
                        </span>
                      }
                      <Form.Item label={"Name " + (api.isActive ? "(Active)" : "(Inactive)")} name={"name" + index} rules={[{ required: true }]} initialValue={api.name}>
                        <Input value={api.name} onChange={(e) => {
                          api.name = e.target.value;
                          _setAccount({ ...account })
                        }} />
                      </Form.Item>
                      <Field value={api.reportKey} label="Report Key" onChange={(value) => {
                        api.reportKey = value;
                        _setAccount({ ...account })
                      }}></Field>
                      <Field value={api.managerKey} label="Manager Key" onChange={(value) => {
                        api.managerKey = value;
                        _setAccount({ ...account })
                      }}></Field>
                      <Form.Item label="Is Active">
                        <Switch checked={api.isActive} onChange={(e) => {
                          api.isActive = e;
                          _setAccount({ ...account })
                        }}></Switch>
                      </Form.Item>
                    </div>
                  ))}
                  {(!account.UsersApis || !account.UsersApis!.find((e) => !e.id)) &&
                    <Button onClick={async () => {
                      _setAccount({
                        ...account,
                        UsersApis: [...(account.UsersApis || []), {
                          name: "",
                          reportKey: await profileService.generateKey(),
                          managerKey: await profileService.generateKey(),
                          isActive: true,
                        }]
                      })
                    }}>+ Add Keys</Button>
                  }
                  <hr />
                  <Button type="primary" htmlType='submit' >Update Keys</Button>
                </Form>
              </Card>
            }
          </div>
          <div className='col-lg-4'>
            <Card title="Change password" >
              <Form onFinish={onUpdatePassword} layout="vertical">
                <Form.Item label="Current Password" name="currentPassword" rules={[{ required: true }]}>
                  <Input type="password" />
                </Form.Item>
                <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                  <Input type="password" />
                </Form.Item>
                <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true }]}>
                  <Input type="password" />
                </Form.Item>
                <Button type="primary" htmlType='submit' >Change password</Button>
              </Form>
            </Card>
          </div>
          <div className='col-lg-8'>
            <Card title="Tenjin" >
              <Form onFinish={onUpdateTenjin} layout="vertical">
                <Form.Item label="Active" name="isActive" initialValue={account.tenjin!.isActive}>
                  <Switch defaultChecked={account.tenjin!.isActive} />
                </Form.Item>
                <Form.Item label="Token" name="token" initialValue={account.tenjin!.token}>
                  <TextArea rows={7} />
                </Form.Item>
                <Button type="primary" htmlType='submit' >Update Tenjin</Button>
              </Form>
            </Card>
          </div>
        </div>
      }
    </div>
  );
}