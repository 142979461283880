import { BaseService } from "./base.service";
import { MApplication } from '../modeles';

export interface GamesServiceHome {
  tops: MApplication[],
  categories: {
    label: string,
    tops: MApplication[],
    games: MApplication[],
  }[],
}

export interface GamesServiceView {
  game: MApplication,
  right: MApplication[],
  bottom: MApplication[],
}
class GamesService extends BaseService {

  PATH = "games/";

  async view(key: string): Promise<GamesServiceView | null> {
    return await this._view(this.PATH + "view/" + key);
  }
  async home(): Promise<GamesServiceHome | null> {
    return await this._view(this.PATH + "home");
  }

}

export const gamesService = new GamesService();