import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

// styles
import './index.scss';

// pages
import { ApplicationsPage, ApplicationPage } from './pages';

export function ApplicationsModule() {
  const { path } = useRouteMatch();

  return (
    <div id="publishing">
      <Switch>
        <Route exact path={`${path}`}>
          <ApplicationsPage />
        </Route>
        <Route path={`${path}/application/:id`}>
          <ApplicationPage />
        </Route>
        <Route path={`${path}/application`}>
          <ApplicationPage />
        </Route>
        <Route path={path}>
          <Redirect to={`${path}`} />
        </Route>
      </Switch>
    </div>
  );
}
