import Avatar from 'antd/lib/avatar/avatar';
import { Link } from 'react-router-dom';
import { useAuths } from '../../contexts/auths.context';
import { UserOutlined } from '@ant-design/icons';

// styles
import './header.scss';
import { Popover } from 'antd';

export function YcHeader() {
  const uAuths = useAuths();

  const onLogout = async () => {
    await uAuths.logout();
  };

  return (
    <nav id="header-component" className="navbar navbar-expand">
      <Link to="/">
        <img title="Yso Games" alt="Logo Yso Corp" src="/logo-ysogames.svg" />
      </Link>
      <div className="links">
        <div className="navbar-content">
          <ul className="navbar-nav">
            {uAuths.isLogin() === true && (
              <>
                <li className="nav-item">
                  <Popover
                    placement="bottomRight"
                    title={
                      <>
                        <div>
                          <b>{uAuths.user?.Account.name}</b>
                        </div>
                        {uAuths.user?.email}
                      </>
                    }
                    content={
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <Link to="/profile">My Profile</Link>
                        </li>
                        <li className="list-group-item">
                          <Link to="/applications">My Applicaitons</Link>
                        </li>
                        {uAuths.isAdmin() && (
                          <li className="list-group-item">
                            <Link to="/users">Users</Link>
                          </li>
                        )}
                        <li className="list-group-item">
                          <Link to="/login" onClick={onLogout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    }
                    trigger="click"
                  >
                    <a href="#/">
                      <Avatar icon={<UserOutlined />} />
                    </a>
                  </Popover>
                </li>
              </>
            )}
            {uAuths.isLogin() === false && (
              <>
                <div className="nav-categories">
                  <li className="nav-item">
                    <i className="ri-trophy-line ico-nav-style"></i>
                    <a href="#">Top games</a>
                  </li>
                  <li className="nav-item">
                    <i className="ri-infrared-thermometer-line ico-nav-style"></i>
                    <a href="#">Action</a>
                  </li>
                  <li className="nav-item">
                    <i className="ri-run-line ico-nav-style"></i>
                    <a href="#">Runner</a>
                  </li>
                  <li className="nav-item">
                    <i className="ri-gamepad-line ico-nav-style"></i>
                    <a href="#">Arcade</a>
                  </li>
                  <li className="nav-item">
                    <i className="ri-sword-line ico-nav-style"></i>
                    <a href="#">Adventure</a>
                  </li>
                  <li className="nav-item">
                    <i className="ri-bring-forward ico-nav-style"></i>
                    <a href="#">Puzzle</a>
                  </li>
                </div>

                <li className="nav-item">
                  <Link className="login" to="/login">
                    Login / Sign up
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
