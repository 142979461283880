import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';

// styles
import './forgot-password.page.scss';

export function ForgotPasswordPage() {
  const onFinish = () => {};

  return (
    <div className="root-forgot-password" id="root-forgot-password">
      <h1 className="title-forgot">Forgot password</h1>
      <div className="block">
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button type="primary">Send</Button>
            <Link className="pl-3" to="/login">
              Login
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
