import { Route, Switch, Redirect } from "react-router-dom";

import { useAuths } from '../../contexts/auths.context';

// styles
import './index.scss';

import { HomePage, SignUpPage, LoginPage, ForgotPasswordPage, ProfilePage, UsersPage, UserPage, GamePage } from './pages';

export function RootModule() {

  const uAuths = useAuths();

  return (
    <Switch>
      <Route exact path="/"><HomePage /></Route>
      <Route exact path="/game/:key"><GamePage /></Route>
      {uAuths.isLogin() === false &&
        <>
          <Route path="/login"><LoginPage /></Route>
          <Route path="/sign-up"><SignUpPage /></Route>
          <Route path="/forgot-password"><ForgotPasswordPage /></Route>
        </>
      }
      {uAuths.isLogin() === true &&
        <>
          <Route path="/profile"><ProfilePage /></Route>
          <Route exact={true} path="/users"><UsersPage /></Route>
          <Route path="/users/user/:id"><UserPage /></Route>
          <Route exact={true} path="/users/user"><UserPage /></Route>
        </>
      }
      <Route path="*"><Redirect to="/" /></Route>
    </Switch>
  );

}
