import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import './login.page.scss';
import { useAuths } from '../../../../contexts/auths.context';

export function LoginPage() {
  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    await uAuths.login(data);
  };

  return (
    <div className="root-login">
      <h1 className="title-login">Login</h1>
      <div className="block">
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true }]}>
            <Input placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
            <Link className="pl-4" to="/sign-up">
              Sign Up
            </Link>
            <Link className="pl-4" to="/forgot-password">
              Forgot Password
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
