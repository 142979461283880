import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { message } from 'antd';

export class BaseService {

  setToken(token: string) {
    localStorage.setItem("user-token", token);
  }

  getToken(): string {
    return localStorage.getItem("user-token") || "";
  }

  delToken() {
    localStorage.removeItem("user-token");
  }

  private getCleanUrl(...strings: string[]) {
    return strings.join('/').replace(/\/+/g, '/');
  }

  public _getMessage(data: any) {
    const message = data.message || data.messages;
    if (typeof message === "string") {
      return message;
    } else if (typeof message === "object") {
      const obj = Object.entries(message);
      let msg = "";
      for (let [key, value] of obj) {
        msg += key + " " + (value as any).msg + "\n";
      }
      return msg;
    }
    return "Error";
  }

  private async request(path: string, config: AxiosRequestConfig) {
    try {
      if (!config.baseURL) {
        config.baseURL = process.env.REACT_APP_API_URL;
      }
      if (!config.headers || !config.headers["authorization"]) {
        config.headers = {
          ...config.headers,
          "authorization": this.getToken()
        };
      }
      const res: AxiosResponse = await Axios(this.getCleanUrl(path), config);
      if (res.data.message || res.data.messages) {
        message.success(this._getMessage(res.data));
      }
      return res.data;
    } catch (e) {
      const res: AxiosResponse | undefined = (e as AxiosError).response;
      if (res?.data.message || res?.data.messages) {
        message.error(this._getMessage(res!.data));
      }
      return null;
    }
  }

  public get<ParamsType>(path: string, params: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'GET',
      params,
    });
  }

  public post<BodyType, ParamsType>(path: string, data: BodyType, params?: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'POST',
      data,
      params,
    });
  }

  public put<BodyType>(path: string, data: BodyType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'PUT',
      data,
    });
  }

  public delete<ParamsType>(path: string, params: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'DELETE',
      params,
    });
  }

  public async _add<T>(path: string, app: T): Promise<T | null> {
    const p = await bc.post(path, app);
    if (p) {
      return p.data;
    }
    return null;
  }

  public async _edit<T, ParamsType>(path: string, data: T, params?: ParamsType): Promise<T | null> {
    const p = await bc.post(path, data, params);
    if (p) {
      return p.data;
    }
    return null;
  }

  public async _toogle(path: string, data = {}): Promise<boolean | null> {
    const p = await bc.get(path, data);
    if (p) {
      return p.data;
    }
    return null;
  }

  public async _view<T>(path: string, data = {}): Promise<T | null> {
    const p = await bc.get(path, data);
    if (p) {
      return p.data;
    }
    return null;
  }

  public async _getSuccess(path: string, data = {}): Promise<boolean> {
    const p = await bc.get(path, data);
    if (p) {
      return true;
    }
    return false;
  }

  public async _listing<T>(path: string, params = {}): Promise<T[]> {
    const apps = await bc.get(path, params);
    if (apps) {
      return apps.data;
    }
    return [];
  }

  public async _listingPost<T>(path: string, data = {}): Promise<T[]> {
    const apps = await bc.post(path, data);
    if (apps) {
      return apps.data;
    }
    return [];
  }

}

export const bc = new BaseService();