import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useAuths } from '../../../../contexts/auths.context';

// styles
import './sign-up.page.scss';

export function SignUpPage() {
  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    await uAuths.signup(data);
  };

  return (
    <div className="root-sign-up">
      <h1 className="title-sign-up">Sign Up</h1>
      <div className="block">
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="Company Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Email" type="text" />
          </Form.Item>
          <Form.Item label="Email" name={['user', 'email']} rules={[{ required: true }]}>
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item label="Password" name={['user', 'password']} rules={[{ required: true }]}>
            <Input placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
            <Link className="pl-3" to="/login">
              Login
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
