import { BaseService } from "./base.service";
import { MApplication, MBuild } from '../modeles';

class ApplicationsService extends BaseService {

  PATH = "applications/";

  async listing(): Promise<MApplication[] | null> {
    return await this._listing(this.PATH + "listing");
  }

  async add(campaign: MApplication): Promise<MApplication | null> {
    return await this._add(this.PATH + "add", campaign);
  }

  async view(id: number): Promise<MApplication | null> {
    return await this._view(this.PATH + "view/" + id);
  }

  async edit(id: number, app: MApplication): Promise<MApplication | null> {
    return await this._edit(this.PATH + "edit/" + id, app);
  }

  async buildCurrect(id: number, buildId: number): Promise<MBuild[] | null> {
    return await this._view(this.PATH + "build-current/" + id + "/" + buildId);
  }

  async activeToogle(id: number): Promise<boolean | null> {
    return await this._toogle(this.PATH + "active-toogle/" + id);
  }

  async topToogle(id: number): Promise<boolean | null> {
    return await this._toogle(this.PATH + "top-toogle/" + id);
  }

}

export const applicationsService = new ApplicationsService();