import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { YcGamePreview } from '../../../../components';
import { gamesService, GamesServiceView } from '../../../../services';
import './game.page.scss';
import { Card } from 'antd';

export function GamePage() {
  const [games, _setGames] = useState<GamesServiceView | null>(null);

  const uParams = useParams<{ key: string }>();
  const uHistory = useHistory();

  useEffect(() => {
    loadApplication();
  }, []);

  useEffect(() => {
    loadApplication();
  }, [uHistory, uParams]);

  const loadApplication = async () => {
    if (!!uParams.key) {
      _setGames(null);
      const data = await gamesService.view(uParams.key);
      if (data) {
        console.log('data: ', data);
        _setGames(data);
      } else {
        uHistory.push('/');
      }
    } else {
      uHistory.push('/');
    }
  };

  return (
    <div id="root-game">
      {!!games && (
        <>
          <div className="player-game-page">
            <div className="col-left">
              <iframe className="player-style" width="960px" height="600px" src={games.game!.url} />
            </div>
            <div className="col-right-infogame">
              <div className="description-header">
                <img className="icon-style" alt="icon app" src={games.game!.Icon!.url} />

                <div className="content-bt-category">
                  <a href="/">
                    <button className="bt-category separation">
                      <i className="ri-trophy-line ico-style" />
                      Top game
                    </button>
                  </a>
                  <a href="/">
                    <button className="bt-category">
                      <i className="ri-sword-line ico-style" />
                      Adventure
                    </button>
                  </a>
                </div>
              </div>
              <div className="description-content">
                <div className="title-game">{games.game!.name}</div>
                <p>{games.game!.description ? games.game!.description : `${games.game.name} is a fun game made with love by YsoCorp!`}</p>
              </div>
              <div className="content-bt-store">
                <a href="https://play.google.com/store/apps/developer?id=Yso+Corp&gl=FR">
                  <button className="bt-store separation">
                    <i className="ri-google-play-fill ico-style" />
                    Google Play
                  </button>
                </a>
                <a href="https://apps.apple.com/fr/developer/yso-corp/id659815325">
                  <button className="bt-store">
                    <i className="ri-apple-fill ico-style" />
                    App Store
                  </button>
                </a>
              </div>
            </div>

            <div className="col-right-studio">
              <div className="studio-header">
                <img className="visual-style" alt="promote visual" src="https://www.ysocorp.com/assets/images/some-success/ico-game.svg" />
              </div>
              <div className="studio-content">
                <div className="title-studio">Do you want to publish your game on the stores?</div>
              </div>
              <div className="content-bt-studio">
                <a href="https://ysocorp.com/#contact">
                  <button className="bt-studio">Contact us</button>
                </a>
              </div>
            </div>
          </div>

          <div className="header-similar-category-style">
            <i className="ri-heart-line ico-style"></i>
            <h2>Similar games</h2>
          </div>

          <div className="games">
            {games!.bottom.map((g, index) => (
              <div className="game" key={index}>
                <YcGamePreview application={g} height={130} />
              </div>
            ))}
          </div>
          <div className="clearfix"></div>
        </>
      )}
    </div>
  );
}
