import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

// styles
import './title.scss';

export function YcTitle(props: {
  links?: [{
    link: string,
    label: string,
  }],
  label: string,
  addUrl?: string,
}) {
  return (
    <h1 className="c-title">
      {props.links && props.links.map((link, index) =>
        <span key={index}>
          <Link to={link.link}>{link.label}</Link> &gt;&nbsp;
        </span>
      )}
      {!props.label && <>New</>}
      {props.label && <>{props.label}</>}
      {!!props.addUrl && 
        <>
          &nbsp;&nbsp;
          <Link type="primary" to={props.addUrl} className="icon"><PlusOutlined /></Link>
        </>
      }
    </h1>
  );
}