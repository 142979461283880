import { Form, Upload, message, Spin } from 'antd';
import { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

// styles
import './upload-file.scss';
import { MDocument } from '../../modeles';
import { applicationsService } from '../../services';

export function YcUploadFile(props: {
  defaultValue: MDocument | undefined,
  width: number,
  height: number,
  name: string,
  displayHeight: number,
  path: string,
  label: string,
  type: 'image' | 'video',
  onUpload: (doc: MDocument) => void
}) {
  const [loading, _setLoading] = useState(false);
  const [icon, _setIcon] = useState<MDocument | undefined>(props.defaultValue);

  const divButton = (
    <>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{props.label}</div>
    </>
  );

  function onChange(info: any) {
    if (info.file.status === 'uploading') {
      _setLoading(true);
    } else if (info.file.status === 'done') {
      _setLoading(false);
      _setIcon(info.file.response.data);
      props.onUpload(info.file.response.data);
    } else if (info.file.status === 'error') {
      _setLoading(false);
      const msg = applicationsService._getMessage(info.file.response);
      if (msg) {
        message.error(msg);
      }
    }
  };

  return (
    <div className="upload-file" style={{
      width: (props.displayHeight / props.height * props.width) + "px",
      height: props.displayHeight + "px"
    }}>
      <Spin spinning={loading}>
        <Form.Item name={props.name} initialValue={icon?.url}>
          <Upload
            name={props.name}
            showUploadList={false}
            onChange={onChange}
            headers={{
              "authorization": localStorage.getItem("user-token") || ""
            }}
            action={process.env.REACT_APP_API_URL + props.path}
          >
            {props.type === 'image' && (!!icon?.url ? <img src={icon?.url} alt="avatar" style={{ width: '100%' }} /> : divButton)}
            {props.type === 'video' && (!!icon?.url ? <video src={icon?.url} style={{ width: '100%' }} /> : divButton)}
          </Upload>
        </Form.Item>
      </Spin>
    </div>
  );
}