import { MApplication } from "../../modeles";

// styles
import './icon.scss';

export function YcIcon(props: {
  application?: MApplication
}) {

  return (
    <span className="c-icon">
      {!!props.application?.Icon?.url && <img alt="icon" src={props.application?.Icon?.url} />}
      {!props.application?.Icon?.url && <div className="dicon"></div>}
    </span>
  );
}