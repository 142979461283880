import { Switch, Route } from "react-router-dom";

import { useAuths } from "../contexts/auths.context";
import { YcHeader } from "../components";

import './app.scss';

import {
  ApplicationsModule,
  RootModule,
} from './';

export function App() {

  const uAuths = useAuths();

  return (
    <div id="app">
      <YcHeader />
      {uAuths.isReady() &&
        <div className="content">
          <Switch>
            <Route path="/applications"><ApplicationsModule /></Route>
            <Route path="/*"><RootModule /></Route>
          </Switch>
        </div>
      }
    </div>
  );
}
