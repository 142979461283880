import { MAccount, MUserApi } from "../modeles";
import { BaseService } from "./base.service";

class ProfileService extends BaseService {

  async view(): Promise<MAccount | null> {
    return await this._view("profile/view");
  }

  async viewManagerOrReportKey(keyName: "reportKey" | "managerKey"): Promise<string | null> {
    const profil = await this.view();
    if (profil && profil.UsersApis && profil.UsersApis.length > 0) {
      for (const api of profil.UsersApis) {
        if (api.isActive) {
          return api[keyName];
        }
      }
    }
    return "";
  }

  async mmps(): Promise<any | null> {
    return this._view("profile/mmps");
  }

  async updatePassword(data: any) {
    await this.post("profile/update-password", data);
  }

  async updateTenjin(data: any) {
    await this.post("profile/update-tenjin", data);
  }

  async updateKeys(data: MUserApi[]): Promise<MUserApi[]> {
    return (await super.post("profile/update-keys", data))?.data;
  }

  async generateKey(): Promise<string> {
    return (await super.get("profile/generate-key", null))?.data;
  }

}

export const profileService = new ProfileService();