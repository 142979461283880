
export class MUser {
  id: number = 0;
  email: string = "";
  isActive: boolean = true;
  isAdmin: boolean = true;
  createdAt?: Date;
}

export class MUserApi {
  id?: number = 0;
  name: string = "";
  reportKey: string = "";
  managerKey: string = "";
  isActive: boolean = true;
}
