import { Link } from "react-router-dom";
import { MApplication } from "../../modeles";

// styles
import './game-preview.scss';

export function YcGamePreview(props: {
  application: MApplication,
  height: number
}) {

  return (
    <Link to={"/game/" + props.application.key} className="yc-game-preview" style={{ width: props.height / 600 * 960, height: props.height }}>
      <img alt="icon" src={props.application.Card?.url} />
      <video src={props.application.Video?.url} autoPlay={true} muted={true} loop={true} />
    </Link>
  );
}