export enum e_ApplicationType {
  action,
  adventure,
  arcade,
  beauty,
  clicker,
  driving,
  io,
  puzzle,
  runner,
  shooting,
  sports,
}

export const ApplicationTypes = Object.values(e_ApplicationType).filter(e => typeof e === "string").map((e) => ({
  key: e,
  label: e,
}));