import { Link } from "react-router-dom";
import { Table, Spin, Button } from 'antd';
import { CheckOutlined, StarOutlined } from '@ant-design/icons';
import { ColumnType } from "antd/lib/table";

import { MApplication } from '../../../../modeles';
import { useEffect, useState } from 'react';
import { applicationsService } from '../../../../services';
import { YcIcon, YcTitle } from "../../../../components";

import './applications.page.scss';

export function ApplicationsPage() {
  const [applications, _setApplications] = useState<MApplication[]>([]);
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    _setLoading(true);
    applicationsService.listing().then((data) => {
      if (data) {
        _setApplications(data);
      }
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnType<MApplication>[] = [
    {
      title: 'Icon', key: 'icon',
      render: (text: string, record: MApplication) => (
        <YcIcon application={record} />
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'Name', key: 'name',
      render: (text: string, record: MApplication) => (
        <Link to={"/applications/application/" + record.id}>{record.name}</Link>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'Last Build', key: 'build',
      render: (text: string, record: MApplication) => (
        <>
          {record.Build &&
            <>{record.Build.version}</>
          }
        </>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'Action', key: 'Action',
      render: (text: string, record: MApplication) => (
        <div>
          <Button type={record.isActive ? "primary" : "ghost"} shape="circle" icon={<CheckOutlined />} onClick={async () => {
            const b = await applicationsService.activeToogle(record.id);
            if (b !== null) {
              record.isActive = b;
              _setApplications([...applications]);
            }
          }} />
          &nbsp;
          <Button type={record.isTop ? "primary" : "ghost"} shape="circle" icon={<StarOutlined />} onClick={async () => {
            const b = await applicationsService.topToogle(record.id);
            if (b !== null) {
              record.isTop = b;
              _setApplications([...applications]);
            }
          }} />
        </div>
      )
    }
  ];

  return (
    <div id='applications-applications'>
      <YcTitle label="Applications" addUrl='/applications/application'></YcTitle>
      <Spin spinning={loading}>
        <Table bordered size="small" dataSource={applications} columns={columns} rowKey="id"></Table>
      </Spin>
    </div>
  );
}