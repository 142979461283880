import { Link } from 'react-router-dom';
import { MApplication } from '../../modeles';

// styles
import './first-top-game.scss';

export function YcFirstTopGame(props: { application: MApplication }) {
  return (
    <div className="yc-first-top-game">
      <div className="col-left">
        <Link to={'/game/' + props.application.key} className="yc-game-preview" style={{ width: (230 / 600) * 960, height: 230 }}>
          <img alt="icon" src={props.application.Card?.url} />
          <video src={props.application.Video?.url} autoPlay={true} muted={true} loop={true} />
        </Link>
      </div>

      <div className="col-right">
        <div className="title-game">{props.application.name}</div>
        <p>{props.application.description ? props.application.description : `${props.application.name} is a fun game made with love by YsoCorp!`}</p>
        <br />
        <div className="content-bt-play">
          <Link to={'/game/' + props.application.key}>
            <button className="bt-play">Play the game</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
